import React from 'react';
import { bool, string, shape } from 'prop-types';

import TabsNavigation from '../TabsNavigation';
import StartInvestingButtons from '../StartInvestingButtons';

export const MainPanel = ({ wrapperType, isFormCompleted, mainHeadingId, params }) => (
  <>
    <TabsNavigation
      wrapperType={wrapperType}
      isFormCompleted={isFormCompleted}
      params={params}
      mainHeadingId={mainHeadingId}
    />
    <StartInvestingButtons wrapperType={wrapperType} params={params} disabled={!isFormCompleted} />
  </>
);

MainPanel.propTypes = {
  wrapperType: string,
  params: shape({}),
  mainHeadingId: string,
  isFormCompleted: bool.isRequired
};

MainPanel.defaultProps = {
  wrapperType: null,
  mainHeadingId: undefined,
  params: {}
};

export default MainPanel;
