import { Children } from 'react';

import { INITIAL_VALUES } from '../../constants';

export const getInitialFormValues = (children, params) =>
  // Only accept form params that have the ID defined in Children components.

  Children.toArray(children).reduce((obj, { props: { id } }) => {
    const queryParam = params[id];

    // overwriting default value only if there is a matching query param
    obj[id] = queryParam || INITIAL_VALUES[id];

    return obj;
  }, {});
