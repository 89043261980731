import React, { cloneElement } from 'react';
import { func, node, string, shape, oneOfType, arrayOf, bool } from 'prop-types';
import { Formik, Form } from 'formik';
import { trackFieldChange } from '@nm-utils-lib-web/analytics';

import { fieldsTrackingOptions } from '../../../../helpers/trackAnalyticsEvents';
import { getValidationSchema, getInitialFormValues, recursiveMap } from '../../../../helpers';
import { TRACKING_CATEGORIES } from '../../../../constants';
import PotFormOnChange from '../PotFormOnChange';

const PotForm = ({ 'data-qa': dataQa, onFormChange, wrapperType, children, disabled, params }) => {
  const inputChildren = recursiveMap(children, child => cloneElement(child));
  const initialValues = getInitialFormValues(inputChildren, params);

  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={
        /* https://github.com/nutmegdevelopment/nm-try-web/pull/154#discussion_r915353563 */
        /* istanbul ignore next */
        () => getValidationSchema(wrapperType, inputChildren)
      }
      initialStatus={{
        trackFieldChange: trackFieldChange(fieldsTrackingOptions, TRACKING_CATEGORIES.ONBOARDING)
      }}
    >
      {formik => {
        const fields = recursiveMap(
          children,
          Child =>
            cloneElement(Child, {
              formik,
              disabled,
              wrapperType
            }),
          true
        );

        return (
          <Form data-qa={dataQa}>
            <PotFormOnChange formik={formik} onFormChange={onFormChange} wrapperType={wrapperType} />
            {fields}
          </Form>
        );
      }}
    </Formik>
  );
};

PotForm.propTypes = {
  'data-qa': string,
  disabled: bool,
  onFormChange: func.isRequired,
  wrapperType: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
  params: shape({})
};

PotForm.defaultProps = {
  'data-qa': 'common-pot-form',
  disabled: false,

  wrapperType: '',
  params: {}
};

export default PotForm;
