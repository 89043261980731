import merge from 'lodash.merge';
import { nutmeg as nutmegPot, johnlewisfinanceGBR as johnlewisPot } from '@nm-pot/languages';
import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import { nutmeg as nutmegTry, johnlewisfinanceGBR as jlfTry } from '../languages';

export default new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...nutmegPot,
        ...nutmegTry,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
      },
      [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
        ...merge(
          {},
          {
            ...nutmegTry,
            ...johnlewisPot
          },
          jlfTry
        ),
        ...getCommonTranslations(ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR)
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
        ...nutmegPot,
        ...nutmegTry,
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR)
      }
    }
  }
});
