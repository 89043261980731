import React from 'react';
import { func, string } from 'prop-types';
import classnames from 'classnames';
import Panel from '@nutkit/component-panel';
import { Text, textSizes, Heading, headingLevels } from '@nutkit/component-text';
import { isNutmegGBRBigBear } from '@nm-utils-lib-web/organisations';

import { getFullPNGImage } from '../../utils';
import { useImageMeta } from '../../hooks';

import styles from './NoProjection.module.scss';

const TRANSLATION_NAMESPACE = 'try.tabs.projection';

export const NoProjection = ({ t, mainHeadingId }) => {
  const image = getFullPNGImage('noProjection');
  const { ratio } = useImageMeta(image);
  let styleImages = {
    backgroundImage: `url(${image})`,
    paddingBottom: `${ratio}%`
  };

  if (isNutmegGBRBigBear()) {
    styleImages = {
      ...styleImages,
      paddingBottom: `${ratio / 2}%`,
      backgroundSize: 'initial'
    };
  }
  const classes = classnames(styles.nmNoProjection, styles.nmNoProjectionBackground);

  return (
    <Panel className={classes} style={styleImages} data-qa="no-projection-component">
      <Heading id={mainHeadingId} level={headingLevels.TWO}>
        {t(`${TRANSLATION_NAMESPACE}.noProjection.heading`)}
      </Heading>
      <Text textSize={textSizes.SM}>{t(`${TRANSLATION_NAMESPACE}.noProjection.description`)}</Text>
    </Panel>
  );
};

NoProjection.propTypes = {
  t: func.isRequired,
  mainHeadingId: string
};

NoProjection.defaultProps = {
  mainHeadingId: undefined
};

export default NoProjection;
