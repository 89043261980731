import React from 'react';
import { any, arrayOf, bool, node, oneOfType, shape, string, func } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Panel from '@nutkit/component-panel';

const defaultArray = [];

const TabErrorHandler = ({ children, message, showLayout, title, resetOnPropsChange }) => {
  const [error, setError] = React.useState(false);
  const onChildError = () => setError(true);

  // Reset error states when specified form values change
  React.useEffect(() => setError(false), [setError, resetOnPropsChange]);
  const Layout = showLayout ? Panel : React.Fragment;

  return error ? (
    <Layout>
      {title && <Heading level={headingLevels.TWO}>{title}</Heading>}
      <Notification level={notificationLevels.ERROR} dismissable={false}>
        {message}
      </Notification>
    </Layout>
  ) : typeof children === 'function' ? (
    children(onChildError)
  ) : (
    children
  );
};

TabErrorHandler.propTypes = {
  children: oneOfType([arrayOf(node), node, func]).isRequired,
  message: string.isRequired,
  resetOnPropsChange: oneOfType([arrayOf(any), shape({})]),
  showLayout: bool,
  title: string
};

TabErrorHandler.defaultProps = {
  resetOnPropsChange: defaultArray,
  showLayout: false,
  title: null
};

export default TabErrorHandler;
