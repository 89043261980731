export const getAverageInvestmentFundCost = (costs, investmentStyle) => costs[investmentStyle].etf;
export const getAverageMarketSpread = (costs, investmentStyle) => costs[investmentStyle].spread;
export const getFeeThreshold = (fees, investmentStyle) => ({
  lowerThresholdRate: Number((fees[investmentStyle][0].rate * 100).toFixed(2)),
  upperThresholdRate: Number((fees[investmentStyle][1].rate * 100).toFixed(2)),
  upperThresholdAmount: Number((fees[investmentStyle][1].threshold / 1000).toFixed(0))
});
export const getCashFee = (fees, investmentStyle) => {
  const lowerThresholdRate = (fees[investmentStyle][0].rate * 100).toFixed(2);

  return `${lowerThresholdRate}%`;
};
