import { Children } from 'react';
import * as Yup from 'yup';

import { WRAPPER_TYPES, FIELDS, LIMITS } from '../../constants';
import { WRAPPER_LIMITS } from '../../constants/limits';

/*
 * getErrorMessage is extracting the translated errorMessage prop from
 * the form field with the given ID
 * it can receive a customProp to overwrite errorMessage text with another string prop
 */
export const getErrorMessage = (children, id, customProp = false) =>
  Children.toArray(children).reduce(
    (result, { props }) => (props.id === id ? (customProp ? props[customProp] : props.errorMessage) : result),
    ''
  );

const validateStartingAmount = (wrapperType, children) => {
  const errorMessage = getErrorMessage(children, FIELDS.STARTING_AMOUNT);
  const maxErrorMessage = getErrorMessage(children, FIELDS.STARTING_AMOUNT, 'maxErrorMessage');
  const currentWrapperLimit = WRAPPER_LIMITS[FIELDS.STARTING_AMOUNT][wrapperType];
  const minLimit = currentWrapperLimit ? currentWrapperLimit.min : LIMITS.MIN_STARTING_AMOUNT;
  const maxLimit = currentWrapperLimit ? currentWrapperLimit.max : LIMITS.MAX_STARTING_AMOUNT;

  return Yup.number()
    .min(minLimit, errorMessage)
    .max(maxLimit, maxErrorMessage)
    .required(errorMessage)
    .nullable();
};

const validateMonthlyContribution = (wrapperType, children) => {
  const errorMessage = getErrorMessage(children, FIELDS.MONTHLY_CONTRIBUTION);

  return (
    wrapperType === WRAPPER_TYPES.JISA &&
    Yup.number()
      .max(LIMITS.MAX_JISA_CONTRIBUTION, errorMessage)
      .nullable()
  );
};

const validateTimeframe = (wrapperType, children) => {
  const timeframeErrorMessage = getErrorMessage(children, FIELDS.TIMEFRAME);

  return (
    wrapperType !== WRAPPER_TYPES.JISA &&
    wrapperType !== WRAPPER_TYPES.PENSION &&
    Yup.number()
      .min(LIMITS.MIN_TIMEFRAME, timeframeErrorMessage)
      .max(LIMITS.MAX_TIMEFRAME, timeframeErrorMessage)
      .integer(timeframeErrorMessage)
      .required(timeframeErrorMessage)
  );
};

const validateAge = (wrapperType, children) => {
  const errorMessage = getErrorMessage(children, FIELDS.AGE);

  return wrapperType === WRAPPER_TYPES.LISA
    ? Yup.number()
        .min(LIMITS.MIN_LISA_AGE, errorMessage)
        .max(LIMITS.MAX_LISA_AGE, errorMessage)
        .integer(errorMessage)
        .required(errorMessage)
    : wrapperType === WRAPPER_TYPES.PENSION
    ? Yup.number()
        .min(LIMITS.MIN_PENSION_AGE, errorMessage)
        .max(LIMITS.MAX_PENSION_AGE, errorMessage)
        .integer(errorMessage)
        .required(errorMessage)
    : false;
};

const validateChildAge = (wrapperType, children) => {
  const childAgeErrorMessage = getErrorMessage(children, FIELDS.CHILD_AGE);

  return (
    wrapperType === WRAPPER_TYPES.JISA &&
    Yup.number()
      .min(LIMITS.MIN_JISA_SIGNUP_AGE, childAgeErrorMessage)
      .max(LIMITS.MAX_JISA_SIGNUP_AGE, childAgeErrorMessage)
      .integer(childAgeErrorMessage)
      .required(childAgeErrorMessage)
  );
};

const validateRetirementAge = (wrapperType, children) => {
  const retirementAgeErrorMessage = getErrorMessage(children, FIELDS.RETIREMENT_AGE);
  const retirementMaxTimeframeErrorMessage = getErrorMessage(
    children,
    FIELDS.RETIREMENT_AGE,
    'maxTimeframeErrorMessage'
  );

  return (
    wrapperType === WRAPPER_TYPES.PENSION &&
    Yup.number()
      .min(LIMITS.MIN_RETIREMENT_AGE, retirementAgeErrorMessage)
      .max(LIMITS.MAX_RETIREMENT_AGE, retirementAgeErrorMessage)
      .test('retirement-max-timeframe', retirementMaxTimeframeErrorMessage, function(retirementAgeValue) {
        const ageValue = this.parent[FIELDS.AGE];

        return retirementAgeValue - ageValue <= LIMITS.MAX_RETIREMENT_TIMEFRAME;
      })
      .integer(retirementAgeErrorMessage)
      .required(retirementAgeErrorMessage)
      .nullable()
  );
};
const validateGender = wrapperType => wrapperType === WRAPPER_TYPES.PENSION && Yup.string().required();
const validateRiskLevel = () =>
  Yup.number()
    .min(LIMITS.MIN_RISK_LEVEL)
    .required();

export const getValidationSchema = (wrapperType, children) => {
  return Yup.object().shape({
    [FIELDS.INVESTMENT_STYLE]: Yup.string().required(),
    [FIELDS.RISK_LEVEL]: validateRiskLevel(),
    [FIELDS.TIMEFRAME]: validateTimeframe(wrapperType, children),
    [FIELDS.AGE]: validateAge(wrapperType, children),
    [FIELDS.CHILD_AGE]: validateChildAge(wrapperType, children),
    [FIELDS.RETIREMENT_AGE]: validateRetirementAge(wrapperType, children),
    [FIELDS.STARTING_AMOUNT]: validateStartingAmount(wrapperType, children),
    [FIELDS.MONTHLY_CONTRIBUTION]: validateMonthlyContribution(wrapperType, children),
    [FIELDS.GENDER]: validateGender(wrapperType)
  });
};
