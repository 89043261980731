import React from 'react';
import { OrganisationFooter } from '@nm-ui-shared-lib-web/organisation-footer';
import ThemeProvider, { themeNames } from '@nutkit/theme';

const Footer = () => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationFooter translationNameSpace="try.common.footer" showAppDownload />
  </ThemeProvider>
);

export default Footer;
