import React from 'react';
import { func, string } from 'prop-types';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import { List, ListItem } from '@nutkit/component-list';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Trans } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'try.modals.automaticTaxRelief';
const howItWorksListKey = `${TRANSLATION_NAMESPACE}.howItWorksList`;

const TaxReliefModal = ({ 'data-qa': dataQa, openModal, t }) => {
  return (
    <div data-qa={dataQa}>
      <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.howItWorksTitle`)}</Heading>
      <List>
        <Trans i18nKey={howItWorksListKey} components={[<ListItem>{t(howItWorksListKey)}</ListItem>]} />
      </List>

      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.contributionEligibilityTitle`)}</Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.personalContribution`)}</Text>
      <Text>{t(`${TRANSLATION_NAMESPACE}.employerContribution`)}</Text>
      <Text>{t(`${TRANSLATION_NAMESPACE}.allowanceOverflowWarning`)}</Text>
      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.higherRateTitle`)}</Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.higherRateDescription`)}</Text>
      <Heading level={headingLevels.THREE}>{t(`${TRANSLATION_NAMESPACE}.whyTaxReliefTitle`)}</Heading>
      <Text>{t(`${TRANSLATION_NAMESPACE}.whyTaxReliefDescription`)}</Text>
      <ButtonGroup align={buttonGroupAlignments.RIGHT}>
        <Button cta={buttonCtaTypes.PRIMARY} onClick={openModal}>
          {t(`${TRANSLATION_NAMESPACE}.closeButton`)}
        </Button>
      </ButtonGroup>
    </div>
  );
};

TaxReliefModal.propTypes = {
  t: func.isRequired,
  openModal: func.isRequired,
  'data-qa': string
};

TaxReliefModal.defaultProps = {
  'data-qa': 'tax-relief-modal'
};

export default TaxReliefModal;
