import React, { useMemo } from 'react';
import { func, number, string, oneOfType } from 'prop-types';
import Panel from '@nutkit/component-panel';
import Section, { stackSpacing } from '@nutkit/component-section';
import DetailedProspectAllocation from '@nm-pot/allocation/components/DetailedProspectAllocation';
import { InvestmentListProspect } from '@nm-pot/investment';

import TabErrorHandler from '../TabErrorHandler';

const TRANSLATION_NAMESPACE = 'try.portfolio';

export const PortfolioTab = ({ investmentStyle, riskLevel, t }) => {
  const resetTabErrorOnPropsChange = useMemo(() => [investmentStyle, riskLevel], [investmentStyle, riskLevel]);

  return (
    <Panel>
      <TabErrorHandler
        message={t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
        title={t(`${TRANSLATION_NAMESPACE}.title`)}
        resetOnPropsChange={resetTabErrorOnPropsChange}
      >
        {onError => (
          <>
            <Section stackSpacing={stackSpacing.LG}>
              <DetailedProspectAllocation
                investmentStyle={investmentStyle}
                riskLevel={parseInt(riskLevel)}
                onError={onError}
              />
            </Section>
            <Section>
              <InvestmentListProspect
                investmentStyle={investmentStyle}
                riskLevel={parseInt(riskLevel)}
                onError={onError}
              />
            </Section>
          </>
        )}
      </TabErrorHandler>
    </Panel>
  );
};

PortfolioTab.propTypes = {
  investmentStyle: string,
  riskLevel: oneOfType([string, number]),
  t: func.isRequired
};

PortfolioTab.defaultProps = {
  investmentStyle: null,
  riskLevel: null
};

export default PortfolioTab;
