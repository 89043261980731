const imagesUuid = {
  marketingdev: {
    noProjection: 'b77c8531-593e-486c-b9ed-6c1322a1e2c1_noProjection',
    products: '9ee2950b-e190-4fe7-860c-2d5142bc89b3_products',
    sisa: 'f954126a-51dd-4c26-8ec6-42f6dd22d0a0_product-isa-xl',
    gia: 'ae4ba646-a7de-4c3c-8c3d-1035dba58140_product-gia-xl',
    jisa: '60cc4469-10e3-4000-81fc-c59f9fa566bc_product-jisa-xl',
    lisa: '910503af-3def-46db-9983-04736a1d7ed8_product-lisa-xl',
    pension: '3b19473e-470c-4f16-a7c6-2b7829fbd74f_product-pension-xl'
  },
  marketingwebsite: {
    noProjection: 'd6d954dc-d810-4d85-9f15-46214a54b7a3_noProjection',
    products: '915035e0-1c3d-444e-9b22-451bb635fe05_products',
    sisa: 'ff24d37f-1350-4c6b-b588-615f7a1c201f_product-isa-xl',
    gia: '0e26e104-5810-43ed-8ede-e2fe58aa1495_product-gia-xl',
    jisa: 'e9dcbfa8-df49-4054-af92-e302dbeb8ac5_product-jisa-xl',
    lisa: 'daf1406e-8c01-4fd9-bb5d-03604151c066_product-lisa-xl',
    pension: 'db56a2c0-3f0a-47d7-af80-8206ae265dbc_product-pension-xl'
  },
  nutmegnewbrand: {
    noProjection: '0d23cf30-7a25-4645-85c0-814ee414bccb_no-graphs',
    products: '915035e0-1c3d-444e-9b22-451bb635fe05_products',
    sisa: '8daadc42-c0eb-42d5-a00b-3af6d148628c_product-select-sisa',
    gia: 'bcfdda4e-ba6a-4e4b-aa01-7b44079ecbae_product-select-gia',
    jisa: 'e9cf73b4-b807-474e-868b-200e6f304f2c_product-select-jisa',
    lisa: '61b695e2-246b-4251-94dc-3ba2211e104a_product-select-lisa',
    pension: 'e5a11f49-2ac5-4fec-8569-bd29b14e3725_product-select-pension'
  },
  'johnlewis-dev': {
    noProjection: 'c63bc125-ae9c-488d-82fe-05f321d5ce2c_no_projection',
    sisa: 'f87f0bc3-87e2-47af-af06-6258226e2326_isa-highres',
    gia: 'fee38c6e-545a-43e4-9d87-8b981d0e3a48_general-investment-highres',
    jisa: 'c0a64d5d-6d4b-4824-9085-750d3a944b09_jisa-highres',
    lisa: 'f87f0bc3-87e2-47af-af06-6258226e2326_isa-highres',
    pension: 'fee38c6e-545a-43e4-9d87-8b981d0e3a48_general-investment-highres'
  },
  johnlewis: {
    noProjection: '4402d42d-d858-40a2-b98e-6412ea42f8f8_no_projection',
    sisa: 'e1650550-c0d9-482a-a965-3bcee8e6a173_isa-highres',
    gia: 'cd64e62a-b64d-4bec-9021-0c55aac93978_general-investment-highres',
    jisa: 'd5219df9-d972-4ee6-93ba-f3539c3aaf63_jisa-highres',
    lisa: 'e1650550-c0d9-482a-a965-3bcee8e6a173_isa-highres',
    pension: 'cd64e62a-b64d-4bec-9021-0c55aac93978_general-investment-highres'
  }
};

export default imagesUuid;
