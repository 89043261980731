import React from 'react';
import { node } from 'prop-types';
import { LayoutWithSidebar } from '@nutkit/layouts';

import Header from '../Header';
import Footer from '../Footer';

const LAYOUT_ID = 'tryAppLayout';
const MAIN_HEADING_ID = `${LAYOUT_ID}-main-heading`;
const SIDEBAR_HEADING_ID = `${LAYOUT_ID}-sidebar-heading`;

const Layout = ({ header, main, sidebar }) => {
  const ariaConfig = {
    mainProps: {
      'aria-labelledby': MAIN_HEADING_ID
    },
    sidebarProps: {
      'aria-labelledby': SIDEBAR_HEADING_ID
    }
  };

  return (
    <>
      <Header />
      <LayoutWithSidebar
        header={header}
        id={LAYOUT_ID}
        configuration={ariaConfig}
        main={React.cloneElement(main, { mainHeadingId: MAIN_HEADING_ID })}
        sidebar={React.cloneElement(sidebar, { sidebarHeadingId: SIDEBAR_HEADING_ID })}
        sidebarLeft
      />
      <Footer />
    </>
  );
};

Layout.propTypes = {
  header: node.isRequired,
  main: node.isRequired,
  sidebar: node.isRequired
};

export default Layout;
