import React from 'react';
import { string, shape, func, bool } from 'prop-types';
import { Trans } from '@nm-utils-lib-web/translations';
import Amount, { amountStyle, amountTypes } from '@nutkit/component-amount';
import { CurrencyInput } from '@nutkit/component-input';
import { Row, Col } from '@nutkit/component-grid';
import { Text, textStyles, textAligns } from '@nutkit/component-text';

import { TAX_RELIEF_FRACTION } from '../../../../constants/limits';

const TRANSLATION_NAMESPACE = 'try.form.common.inputCurrency';

const InputCurrency = ({ 'data-qa': dataQa, label, disabled, formik, id, noStack, showTaxRelief }) => {
  const onChangeHandler = value => {
    formik.setFieldValue(id, value);
  };

  const onBlurHandler = e => {
    formik.handleBlur(e);
    formik.status.trackFieldChange(id, formik.values[id], formik.errors[id]);
  };
  const taxReliefValue = formik.values[id] ? parseInt(formik.values[id] * TAX_RELIEF_FRACTION) : 0;
  const inputLabel = (
    <Row>
      <Col>{label}</Col>
      {showTaxRelief && (
        <Col>
          <Text textStyle={textStyles.TEXT_2} data-qa="tax-relief-value" textAlign={textAligns.RIGHT} noStack>
            <Trans
              components={[
                <Amount style={amountStyle.CURRENCY} type={amountTypes.PERFORMANCE} value={taxReliefValue} />
              ]}
              i18nKey={`${TRANSLATION_NAMESPACE}.taxRelief`}
            />
          </Text>
        </Col>
      )}
    </Row>
  );

  return (
    <CurrencyInput
      data-qa={dataQa}
      id={id}
      label={inputLabel}
      disabled={disabled}
      error={formik.touched[id] && formik.errors[id]}
      onChange={onChangeHandler}
      value={parseInt(formik.values[id])}
      onBlur={onBlurHandler}
      noStack={noStack}
    />
  );
};

InputCurrency.propTypes = {
  'data-qa': string,
  disabled: bool,
  label: string,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }),
  noStack: bool,
  showTaxRelief: bool,
  id: string
};

InputCurrency.defaultProps = {
  'data-qa': 'input-currency',
  disabled: false,
  noStack: false,
  showTaxRelief: false,
  formik: {},
  label: 'Input Currency',
  id: 'inputCurrency'
};

export default InputCurrency;
