import { ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import { SISA as sisa, JISA as jisa, LISA as lisa, GIA as gia, PENSION as pension } from './wrapperTypes';

const WRAPPER_OPTIONS = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: [sisa, jisa, lisa, gia, pension],
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: [sisa, jisa, gia]
};

export default WRAPPER_OPTIONS;
