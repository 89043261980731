import React, { useMemo } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import qs from 'qs';

const useRouteState = () => {
  const { wrapperType, currentTab } = useParams();
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true }), [location.search]);

  const setParams = newParams => {
    const search = qs.stringify({ ...params, ...newParams }, { addQueryPrefix: true });

    history.push({ search });
  };
  const replaceParams = React.useCallback(
    newParams => {
      const search = qs.stringify(newParams, { addQueryPrefix: true });

      history.push({ search });
    },
    [history]
  );

  const removeParam = removeParam => {
    const search = qs.stringify({ ...params, [removeParam]: undefined }, { addQueryPrefix: true });

    history.push({ search });
  };

  return {
    location,
    history,
    params,
    setParams,
    replaceParams,
    removeParam,
    wrapperType,
    currentTab
  };
};

export default useRouteState;
