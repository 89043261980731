import React from 'react';
import { string, func, shape } from 'prop-types';
import { ProspectCostsAndCharges } from '@nm-pot/costs-and-charges';
import AboutCostsAndChargesModal from '@nm-pot/common/components/Projection/AboutCostsAndChargesModal';
import { Text, Heading, headingLevels, textStyles } from '@nutkit/component-text';
import { BoxWithOutline } from '@nutkit/component-box';
import Section, { stackSpacing } from '@nutkit/component-section';

const TRANSLATION_NAMESPACE = 'try.projection.projectedCostsAndCharges';

const ProjectedCostsAndCharges = ({ wrapperType, inputValues, t, onError }) => {
  const onModalToggle = () => {
    //@TODO - add tracking
  };

  return (
    <>
      <Section stackSpacing={stackSpacing.MD}>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
        <ProspectCostsAndCharges wrapperType={wrapperType} inputValues={inputValues} onError={onError} />
        <AboutCostsAndChargesModal onModalToggle={onModalToggle} />
      </Section>
      <BoxWithOutline>
        <Text noStack textStyle={textStyles.TEXT_2}>
          {t(`${TRANSLATION_NAMESPACE}.legalWarning`)}
        </Text>
        <Text noStack textStyle={textStyles.TEXT_2}>
          {t(`${TRANSLATION_NAMESPACE}.taxTreatment`)}
        </Text>
      </BoxWithOutline>
    </>
  );
};

ProjectedCostsAndCharges.propTypes = {
  wrapperType: string.isRequired,
  t: func.isRequired,
  inputValues: shape({}),
  onError: func.isRequired
};

ProjectedCostsAndCharges.defaultProps = {
  inputValues: {}
};

export default ProjectedCostsAndCharges;
