import React from 'react';
import { func, shape, string } from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import Panel from '@nutkit/component-panel';
import { Heading, headingLevels } from '@nutkit/component-text';

import { WRAPPER_TYPES } from '../../constants';
import JISAForm from '../Form/JISAForm';
import SISAForm from '../Form/SISAForm';
import LISAForm from '../Form/LISAForm';
import PensionForm from '../Form/PensionForm';

const TRANSLATION_NAMESPACE = 'try.common';

export const SidePanel = props => {
  const { t, sidebarHeadingId } = props;

  return (
    <Panel>
      <Heading level={headingLevels.TWO} id={sidebarHeadingId}>
        {t(`${TRANSLATION_NAMESPACE}.sidePanel.heading`)}
      </Heading>
      <Switch>
        <Route path={`/${WRAPPER_TYPES.SISA}`}>
          <SISAForm {...props} />
        </Route>
        <Route path={`/${WRAPPER_TYPES.LISA}`}>
          <LISAForm {...props} />
        </Route>
        <Route path={`/${WRAPPER_TYPES.JISA}`}>
          <JISAForm {...props} />
        </Route>
        <Route path={`/${WRAPPER_TYPES.GIA}`}>
          <SISAForm {...props} />
        </Route>
        <Route path={`/${WRAPPER_TYPES.PENSION}`}>
          <PensionForm {...props} />
        </Route>
        <SISAForm disabled {...props} />
      </Switch>
    </Panel>
  );
};

SidePanel.propTypes = {
  onFormChange: func.isRequired,
  params: shape({}).isRequired,
  sidebarHeadingId: string,
  t: func.isRequired
};

SidePanel.defaultProps = {
  sidebarHeadingId: undefined
};

export default SidePanel;
