import React from 'react';
import debounce from 'lodash.debounce';
import { func, string, shape } from 'prop-types';

const PotFormOnChange = ({ onFormChange, formik, wrapperType }) => {
  const { values, validateForm } = formik;
  const debounceFormChange = React.useCallback(
    (values, isValid) => {
      debounce(() => onFormChange(values, isValid), 500)();
    },
    [onFormChange]
  );

  React.useEffect(() => {
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wrapperType]);

  React.useEffect(() => {
    const request = async () => {
      const errors = await validateForm(values);
      const isValid = Object.keys(errors).length === 0;

      debounceFormChange(values, isValid);
    };

    request();
  }, [values, debounceFormChange, validateForm]);

  return null;
};

PotFormOnChange.propTypes = {
  onFormChange: func.isRequired,
  wrapperType: string,
  formik: shape({ resetForm: func }).isRequired
};

PotFormOnChange.defaultProps = {
  wrapperType: ''
};

export default PotFormOnChange;
