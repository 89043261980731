import React, { useState } from 'react';

import { useRouteState } from '../../hooks';
import { cleanFormValues, trackDetailsSubmitted } from '../../helpers';
import Layout from '../Layout';
import SidePanel from '../SidePanel';
import HeaderPanel from '../HeaderPanel';
import MainPanel from '../MainPanel';
import { trackProductTypeChange } from '../../helpers/trackAnalyticsEvents/trackAnalyticsEvents';

const TryAppContainer = () => {
  const { wrapperType, params, replaceParams, history } = useRouteState();
  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const onWrapperChange = wrapperType => {
    setIsFormCompleted(false);
    trackProductTypeChange({ Product_Type: wrapperType });

    return history.push(`/${wrapperType}`);
  };
  const onFormChange = React.useCallback(
    (formValues, isValid) => {
      const completedValues = cleanFormValues(formValues);

      replaceParams(completedValues);
      isValid && trackDetailsSubmitted({ Product_Type: wrapperType, ...completedValues });
      setIsFormCompleted(isValid);
    },
    [replaceParams, wrapperType]
  );

  return (
    <Layout
      header={<HeaderPanel wrapperType={wrapperType} onChange={onWrapperChange} />}
      sidebar={<SidePanel wrapperType={wrapperType} onFormChange={onFormChange} params={params} />}
      main={<MainPanel wrapperType={wrapperType} params={params} isFormCompleted={isFormCompleted} />}
    />
  );
};

export default TryAppContainer;
