import React from 'react';
import { string, shape, bool, func } from 'prop-types';
import Panel from '@nutkit/component-panel';

import NoProjection from '../NoProjection';
import ProjectionChart from '../ProjectionChart';
import ProjectedCostsAndCharges from '../ProjectedCostsAndCharges';
import TabErrorHandler from '../TabErrorHandler';

const TRANSLATION_NAMESPACE = 'try.projection';

export const ProjectionTab = ({ isFormCompleted, mainHeadingId, params, t, wrapperType }) => {
  return isFormCompleted ? (
    <Panel>
      <TabErrorHandler
        message={t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
        title={t(`${TRANSLATION_NAMESPACE}.projectionChart.title`)}
        resetOnPropsChange={params}
      >
        {onError => (
          <>
            <ProjectionChart
              mainHeadingId={mainHeadingId}
              wrapperType={wrapperType}
              inputValues={params}
              onError={onError}
            />
            <ProjectedCostsAndCharges wrapperType={wrapperType} inputValues={params} onError={onError} />
          </>
        )}
      </TabErrorHandler>
    </Panel>
  ) : (
    <NoProjection mainHeadingId={mainHeadingId} />
  );
};

ProjectionTab.propTypes = {
  isFormCompleted: bool.isRequired,
  params: shape({}),
  t: func.isRequired,
  mainHeadingId: string,
  wrapperType: string
};

ProjectionTab.defaultProps = {
  params: {},
  mainHeadingId: undefined,
  wrapperType: ''
};

export default ProjectionTab;
