import React from 'react';
import { string, func, shape, bool } from 'prop-types';
import Input from '@nutkit/component-input';
import { notificationLevels } from '@nutkit/component-notification';

const InputNumber = ({ 'data-qa': dataQa, disabled, label, formik, id, hideErrorMessage }) => {
  const onChangeHandler = e => {
    const value = e.target.value;

    formik.setFieldValue(id, value);
  };

  const onBlurHandler = e => {
    formik.handleBlur(e);
    formik.status.trackFieldChange(id, formik.values[id], formik.errors[id]);
  };
  const feedback = formik.touched[id] &&
    formik.errors[id] && {
      level: notificationLevels.ERROR,
      children: formik.errors[id]
    };

  return (
    <Input
      data-qa={dataQa}
      disabled={disabled}
      feedback={!hideErrorMessage ? feedback : null}
      noStack={hideErrorMessage}
      label={label}
      type="number"
      id={id}
      value={formik.values[id] || ''}
      onChange={e => onChangeHandler(e)}
      onBlur={onBlurHandler}
    />
  );
};

InputNumber.propTypes = {
  'data-qa': string,
  disabled: bool,
  hideErrorMessage: bool,
  label: string,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }),
  id: string
};

InputNumber.defaultProps = {
  'data-qa': 'input-number',
  disabled: false,
  hideErrorMessage: false,
  formik: {},
  id: 'inputNumber',
  label: 'Input Number'
};

export default InputNumber;
