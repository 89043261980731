import React from 'react';
import { string, shape, func, bool } from 'prop-types';
import { SliderWithLabels, SliderWithHighlight, sliderLabelPresets } from '@nutkit/component-slider';
import { ranges } from '@nm-pot/common/helpers/riskLevelHelpers';

import { FIELDS, LIMITS } from '../../../../constants';

const TRANSLATION_NAMESPACE = 'try.form.common.riskLevel';
const defaultRanges = {
  min: 0,
  max: 0
};

const RiskSlider = ({ 'data-qa': dataQa, disabled, label, formik, id, t }) => {
  const onChangeHandler = ({ value }) => {
    formik.setFieldValue(id, value);
    formik.status.trackFieldChange(id, value, formik.errors[id]);
  };
  const investmestStyleValue = formik.values[FIELDS.INVESTMENT_STYLE];
  const { min, max } = investmestStyleValue ? ranges[investmestStyleValue] : defaultRanges;

  React.useEffect(() => {
    if (formik.values[id] > max) formik.setFieldValue(id, LIMITS.SLIDER_LIMIT_CHANGED_INITIAL_VALUE);
  }, [max, formik, id]);

  return (
    <SliderWithLabels
      data-qa={dataQa}
      disabled={disabled}
      id={id}
      value={parseInt(formik.values[id]) || null}
      labelTopLeft={label}
      labelTopRight={sliderLabelPresets.FRACTION}
      labelBottomLeft={t(`${TRANSLATION_NAMESPACE}.descriptorLow`)}
      labelBottomRight={t(`${TRANSLATION_NAMESPACE}.descriptorHigh`)}
      max={max}
      min={min}
      onChange={onChangeHandler}
      slider={props => <SliderWithHighlight {...props} />}
    />
  );
};

RiskSlider.propTypes = {
  'data-qa': string,
  disabled: bool,
  label: string,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }).isRequired,
  id: string.isRequired,
  t: func.isRequired
};

RiskSlider.defaultProps = {
  'data-qa': 'risk-slider',
  disabled: false,
  label: ''
};
export default RiskSlider;
