import { TRACKING_CATEGORIES, TRACKING_EVENTS, FIELDS } from '../../constants';

export const fieldsTrackingOptions = {
  [TRACKING_CATEGORIES.ONBOARDING]: {
    [FIELDS.STARTING_AMOUNT]: {
      trackingName: TRACKING_EVENTS.TRY_STARTING_AMOUNT,
      trackValue: true
    },
    [FIELDS.MONTHLY_CONTRIBUTION]: {
      trackingName: TRACKING_EVENTS.TRY_MONTHLY_CONTRIBUTIONS,
      trackValue: true
    },
    [FIELDS.TIMEFRAME]: {
      trackingName: TRACKING_EVENTS.TRY_TIMEFRAME,
      trackValue: true
    },
    [FIELDS.INVESTMENT_STYLE]: {
      trackingName: TRACKING_EVENTS.TRY_INVESTMENT_STYLE,
      trackValue: true
    },
    [FIELDS.RISK_LEVEL]: {
      trackingName: TRACKING_EVENTS.TRY_RISK_LEVEL,
      trackValue: true
    },
    [FIELDS.CHILD_AGE]: {
      trackingName: TRACKING_EVENTS.TRY_CHILD_AGE,
      trackValue: true
    },
    [FIELDS.AGE]: {
      trackingName: TRACKING_EVENTS.TRY_AGE,
      trackValue: true
    },
    [FIELDS.TARGET_AMOUNT]: {
      trackingName: TRACKING_EVENTS.TRY_TARGET_AMOUNT,
      trackValue: true
    }
  }
};
