import React, { useMemo } from 'react';
import { string, func, oneOfType, number } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Text } from '@nutkit/component-text';
import HistoricalPerformance from '@nm-pot/common/components/HistoricalPerformance';

import TabErrorHandler from '../TabErrorHandler';

const TRANSLATION_NAMESPACE = 'try.historical';

export const HistoricalTab = ({ investmentStyle, riskLevel, t }) => {
  const resetTabErrorOnPropsChange = useMemo(() => [investmentStyle, riskLevel], [investmentStyle, riskLevel]);

  const onClickLearnMore = () => {
    //Required prop. @TODO: Tracking
  };

  if (!investmentStyle || !riskLevel) {
    return (
      <Panel>
        <Text>{t(`${TRANSLATION_NAMESPACE}.noRiskOrInvestment`)}</Text>
      </Panel>
    );
  }

  return (
    <TabErrorHandler
      message={t(`${TRANSLATION_NAMESPACE}.errorMessage`)}
      resetOnPropsChange={resetTabErrorOnPropsChange}
      showLayout
      title={t('pot.common.historical.title')}
    >
      {onError => (
        <HistoricalPerformance
          investmentStyle={investmentStyle}
          riskLevel={parseInt(riskLevel)}
          onModalChange={onClickLearnMore}
          onError={onError}
        />
      )}
    </TabErrorHandler>
  );
};

HistoricalTab.propTypes = {
  investmentStyle: string,
  riskLevel: oneOfType([string, number]),
  t: func.isRequired
};

HistoricalTab.defaultProps = {
  investmentStyle: '',
  riskLevel: null
};

export default HistoricalTab;
