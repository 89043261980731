import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import Notification, { notificationLevels, notificationSizes } from '@nutkit/component-notification';
import Section, { sectionTagNames, stackSpacing } from '@nutkit/component-section';

const FormErrorMessage = ({ formik, fields }) => {
  const errors = fields.map(id => formik.touched[id] && formik.errors[id]);
  const hasErrors = !!errors.filter(id => id).length;

  return (
    <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.MD}>
      {hasErrors && (
        <Notification
          level={notificationLevels.ERROR}
          size={notificationSizes.XS}
          dismissable={false}
          noStack
          data-qa="try-input__notification"
        >
          <>{errors}</>
        </Notification>
      )}
    </Section>
  );
};

FormErrorMessage.propTypes = {
  formik: shape({
    touched: shape({}),
    errors: shape({})
  }),
  fields: arrayOf(string).isRequired
};

FormErrorMessage.defaultProps = {
  formik: {}
};
export default FormErrorMessage;
