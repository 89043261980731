import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useMaintenanceMode } from '@nm-utils-lib-web/maintenance';
import { apps } from '@nm-utils-lib-web/monitoring';
import { useInitialiseAnalytics } from '@nm-utils-lib-web/analytics';

import TryAppContainer from '../TryAppContainer';

const App = () => {
  const isMaintenanceModeFlagEnabled = useFlag(Flags.MAINTENANCE_MODE);
  const { t } = useTranslation();

  useInitialiseAnalytics({ appName: apps.NM_TRY_WEB });

  useMaintenanceMode(isMaintenanceModeFlagEnabled);

  return (
    <div data-qa="try-nutmeg-app">
      <Helmet>
        <title>{t('global.common.organisation.appTitle')}</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/:wrapperType(sisa|lisa|jisa|gia|pension)?" component={TryAppContainer} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
