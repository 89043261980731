export const TRACKING_CATEGORIES = {
  ONBOARDING: 'Onboarding'
};

export const TRACKING_EVENTS = {
  TRY_POT_DETAILS_SUBMITTED: 'TRY_POT_DETAILS_SUBMITTED',
  TRY_START_INVESTING_CLICKED: 'TRY_START_INVESTING_CLICKED',
  TRY_LOG_IN_CLICKED: 'TRY_LOG_IN_CLICKED',
  TRY_PROJECTION_CLICKED: 'TRY_PROJECTION_CLICKED',
  TRY_PORTFOLIO_CLICKED: 'TRY_PORTFOLIO_CLICKED',
  TRY_HISTORICAL_CLICKED: 'TRY_HISTORICAL_CLICKED',
  TRY_PRODUCT_TYPE: 'TRY_PRODUCT_TYPE',
  TRY_STARTING_AMOUNT: 'TRY_STARTING_AMOUNT',
  TRY_TARGET_AMOUNT: 'TRY_TARGET_AMOUNT',
  TRY_MONTHLY_CONTRIBUTIONS: 'TRY_MONTHLY_CONTRIBUTIONS',
  TRY_TIMEFRAME: 'TRY_TIMEFRAME',
  TRY_INVESTMENT_STYLE: 'TRY_INVESTMENT_STYLE',
  TRY_RISK_LEVEL: 'TRY_RISK_LEVEL',
  TRY_CHILD_AGE: 'TRY_CHILD_AGE',
  TRY_AGE: 'TRY_AGE'
};
