import fetchImage, { imageSizes } from '@nm-marketing/prismic-utils';

import { imagesUuid } from '../../constants';

const getFullPNGImage = (id, options = {}) => {
  const target = global.NutmegConfig.PRISMIC_TARGET;
  const imageKey = imagesUuid[target][id];

  return fetchImage(target, imageKey, imageSizes.AUTO, 'png', options);
};

export default getFullPNGImage;
