import React from 'react';
import { useLocation } from 'react-router-dom';
import { bool, string, shape } from 'prop-types';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { REGISTER_PATH } from '@nm-utils-lib-web/routes/customer';

import { trackStartInvesting, trackLogin } from '../../helpers';

const TRANSLATION_NAMESPACE = 'try.startInvestingButtons';

const StartInvestingButtons = ({ disabled, wrapperType, params }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const redirectToLogin = () => {
    const loginURL = window.NutmegConfig.AUTH_HOST;

    trackLogin({ Product_Type: wrapperType, ...params });
    window.location.assign(loginURL);
  };

  const redirectToRegistration = () => {
    const registerURL = `${window.NutmegConfig.SIGNUP_HOST}${REGISTER_PATH}${location.search}`;

    trackStartInvesting({ Product_Type: wrapperType, ...params });
    window.location.assign(registerURL);
  };

  return (
    <ButtonGroup stackUp stack align={buttonGroupAlignments.RIGHT}>
      <Button
        cta={buttonCtaTypes.SECONDARY}
        onClick={redirectToLogin}
        disabled={disabled}
        data-qa="start-investing-login-button"
      >
        {t(`${TRANSLATION_NAMESPACE}.loginText`)}
      </Button>
      <Button onClick={redirectToRegistration} disabled={disabled} data-qa="start-investing-button">
        {t(`${TRANSLATION_NAMESPACE}.signupText`)}
      </Button>
    </ButtonGroup>
  );
};

StartInvestingButtons.propTypes = {
  disabled: bool.isRequired,
  wrapperType: string,
  params: shape({})
};

StartInvestingButtons.defaultProps = {
  wrapperType: null,
  params: {}
};

export default StartInvestingButtons;
