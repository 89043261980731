import React from 'react';
import { func, shape, string, bool } from 'prop-types';
import classnames from 'classnames';
import { Link as RouterLink, Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import Navigation, { NavigationItem } from '@nutkit/component-navigation';

import PortfolioTab from '../PortfolioTab';
import ProjectionTab from '../ProjectionTab';
import HistoricalTab from '../HistoricalTab';
import { trackTabChange } from '../../helpers';
import { TRACKING_EVENTS } from '../../constants';

import styles from './TabsNavigation.module.scss';

const TRANSLATION_NAMESPACE = 'try.tabs';
const { TRY_PROJECTION_CLICKED, TRY_PORTFOLIO_CLICKED, TRY_HISTORICAL_CLICKED } = TRACKING_EVENTS;

const TabsNavigation = ({ onTabChange, params, isFormCompleted, t, wrapperType, mainHeadingId }) => {
  const match = useRouteMatch();
  const location = useLocation();
  const { investmentStyle, riskLevel } = params;
  const isNavigationDisabled = !wrapperType || !investmentStyle || !riskLevel;
  const navigationItemClassName = classnames(styles.tryTabsNavigationItem, {
    [styles.tryTabsNavigationItemIsDisabled]: isNavigationDisabled
  });
  const routerLinkClassName = classnames(styles.tryTabsLink);

  const handleLocationChange = (event, activeTab, eventName) => {
    if (isNavigationDisabled) {
      event.preventDefault();

      return;
    }

    trackTabChange(eventName);
    onTabChange && onTabChange(activeTab);
  };
  const tryTabsLinks = {
    projection: {
      url: match.url,
      label: t(`${TRANSLATION_NAMESPACE}.projection.title`),
      eventName: TRY_PROJECTION_CLICKED
    },
    portfolio: {
      url: `${match.url}/portfolio`,
      label: t(`${TRANSLATION_NAMESPACE}.portfolio.title`),
      eventName: TRY_PORTFOLIO_CLICKED
    },
    historical: {
      url: `${match.url}/historical`,
      label: t(`${TRANSLATION_NAMESPACE}.historical.title`),
      eventName: TRY_HISTORICAL_CLICKED
    }
  };
  const tabNavigationItems = Object.entries(tryTabsLinks).map(([key, { url, label, eventName }]) => (
    <NavigationItem className={navigationItemClassName} isActive={url === location.pathname} key={label} disabled>
      <RouterLink
        className={routerLinkClassName}
        to={{ pathname: url, search: location.search }}
        onClick={e => handleLocationChange(e, key, eventName)}
        tabIndex={isNavigationDisabled ? -1 : ''}
      >
        {label}
      </RouterLink>
    </NavigationItem>
  ));

  return (
    <>
      <div aria-hidden={isNavigationDisabled}>
        <Navigation data-qa="tab_navigation">{tabNavigationItems}</Navigation>
      </div>
      <Switch>
        <Route path={`${match.path}/portfolio`}>
          <PortfolioTab investmentStyle={investmentStyle} riskLevel={riskLevel} />
        </Route>
        <Route path={`${match.path}/historical`}>
          <HistoricalTab investmentStyle={investmentStyle} riskLevel={riskLevel} />
        </Route>
        <Route path={`${match.path}`}>
          <ProjectionTab
            mainHeadingId={mainHeadingId}
            isFormCompleted={isFormCompleted}
            params={params}
            wrapperType={wrapperType}
          />
        </Route>
      </Switch>
    </>
  );
};

TabsNavigation.propTypes = {
  onTabChange: func,
  params: shape({}),
  mainHeadingId: string,
  t: func.isRequired,
  isFormCompleted: bool.isRequired,
  wrapperType: string
};

TabsNavigation.defaultProps = {
  onTabChange: () => {},
  mainHeadingId: undefined,
  params: {},
  wrapperType: null
};

export default TabsNavigation;
