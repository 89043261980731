import React from 'react';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';

const Header = () => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationHeader
      translationNameSpace="try.common.header"
      logoHref={window.NutmegConfig.MARKETING_HOST}
      showHelp
    />
  </ThemeProvider>
);

export default Header;
