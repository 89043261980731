// eslint-disable-next-line max-len
import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/johnlewisfinance-gbr';
// eslint-disable-next-line max-len
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/johnlewisfinance-gbr';

import * as WRAPPER_TYPES from '../../constants/wrapperTypes';
import common from '../common.json';

export default {
  try: {
    common: {
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      wrapperTypes: {
        [WRAPPER_TYPES.SISA]: {
          ...common.wrapperTypes.sisa,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/isa`
        },
        [WRAPPER_TYPES.JISA]: {
          ...common.wrapperTypes.jisa,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/jisa`
        },
        [WRAPPER_TYPES.GIA]: {
          ...common.wrapperTypes.gia,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/isa#portfolio_benefits`
        }
      }
    }
  }
};
