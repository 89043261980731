import React from 'react';
import { func, string, shape, bool } from 'prop-types';
import Select from '@nutkit/component-select';
import { getOrganisation } from '@nm-utils-lib-web/organisations';

import { FIELDS, AVAILABLE_INVESTMENT_STYLES } from '../../../../constants';

import InvestmentStyleDescription from './InvestmentStyleDescription';

const TRANSLATION_NAMESPACE = 'try.form.common.investmentStyles';

const SelectInvestmentStyle = ({ 'data-qa': dataQa, disabled, label, formik, id, t }) => {
  const value = formik.values[id];
  let options = Object.values(AVAILABLE_INVESTMENT_STYLES[getOrganisation()]).map(investmentStyle => ({
    id: investmentStyle,
    title: t(`${TRANSLATION_NAMESPACE}.${investmentStyle}.title`)
  }));

  const onSelectHandler = value => {
    formik.setFieldValue(id, value);
    formik.status.trackFieldChange(id, value, formik.errors[id]);
  };

  return (
    <div data-qa={dataQa}>
      <Select
        id={id}
        aria-controls={`${FIELDS.RISK_LEVEL}`}
        data-qa={`${dataQa}-select-input`}
        title={t(`${TRANSLATION_NAMESPACE}.placeholder`)}
        label={label}
        disabled={disabled}
        options={options}
        onSelect={onSelectHandler}
        value={value}
      />
      {value && <InvestmentStyleDescription data-qa={`${dataQa}-description`} value={value} />}
    </div>
  );
};

SelectInvestmentStyle.propTypes = {
  label: string,
  'data-qa': string,
  t: func.isRequired,
  disabled: bool,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }).isRequired,
  id: string.isRequired
};

SelectInvestmentStyle.defaultProps = {
  disabled: false,
  label: '',
  'data-qa': 'select-investment-style'
};

export default SelectInvestmentStyle;
