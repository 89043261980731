import * as WRAPPER_TYPES from './wrapperTypes';
import { FIELDS } from './fields';

export const MIN_JISA_STARTING_AMOUNT = 100;
export const MAX_JISA_STARTING_AMOUNT = 9000;
export const MAX_JISA_CONTRIBUTION = 750;
export const MAX_JISA_SIGNUP_AGE = 15;
export const MIN_JISA_SIGNUP_AGE = 0;

export const MIN_STARTING_AMOUNT = 500;
export const MAX_STARTING_AMOUNT = 99999999;

export const MIN_TIMEFRAME = 3;
export const MAX_TIMEFRAME = 50;

export const MIN_RETIREMENT_AGE = 55;
export const MAX_RETIREMENT_AGE = 99;
export const MAX_RETIREMENT_TIMEFRAME = 50;

export const MIN_PENSION_AGE = 18;
export const MAX_PENSION_AGE = 75;

export const MIN_LISA_AGE = 18;
export const MAX_LISA_AGE = 39;

export const MIN_LISA_STARTING_AMOUNT = 100;
export const MAX_LISA_STARTING_AMOUNT = 4000;

export const LISA_WITHDRAW_AGE_LIMIT = 60;

//Inputs Business Rules
export const SLIDER_LIMIT_CHANGED_INITIAL_VALUE = 1;
// Tax Relief rules
export const TAX_RELIEF_FRACTION = 25 / 100;

export const MIN_RISK_LEVEL = 1;

//relationships
export const WRAPPER_LIMITS = Object.freeze({
  [FIELDS.STARTING_AMOUNT]: {
    [WRAPPER_TYPES.JISA]: {
      min: MIN_JISA_STARTING_AMOUNT,
      max: MAX_JISA_STARTING_AMOUNT
    },
    [WRAPPER_TYPES.LISA]: {
      min: MIN_LISA_STARTING_AMOUNT,
      max: MAX_LISA_STARTING_AMOUNT
    }
  }
});
