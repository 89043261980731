import React from 'react';
import { func, shape } from 'prop-types';
import Button, { buttonCtaTypes, buttonSizes } from '@nutkit/component-button';
import { Text } from '@nutkit/component-text';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { Row, Col } from '@nutkit/component-grid';
import { ModalWithTrigger } from '@nutkit/component-modal';

import { FIELDS, GENDERS } from '../../../constants';
import {
  InputNumber,
  InputCurrency,
  InputSelect,
  PotForm,
  RiskSlider,
  SelectInvestmentStyle,
  CheckboxWithModal,
  FormErrorMessage,
  ContributionsToggle
} from '../common';

const TRANSLATION_NAMESPACE = 'try.form.PENSION';

const PensionForm = ({ t, ...props }) => {
  const { params } = props;
  const [showEmployerContributions, setShowEmployerContribution] = React.useState(
    !!(params[FIELDS.EMPLOYER_MONTHLY_CONTRIBUTION] || params[FIELDS.EMPLOYER_ONE_OFF_CONTRIBUTION])
  );

  const handleEmployerContributionsChange = ({ value }) => {
    setShowEmployerContribution(value);
  };

  return (
    <PotForm {...props}>
      <Row>
        <Col xs="6">
          <InputNumber
            label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.AGE}.label`)}
            errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.AGE}.error`)}
            id={FIELDS.AGE}
            data-qa={FIELDS.AGE}
            hideErrorMessage
          />
        </Col>
        <Col xs="6">
          <InputNumber
            label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.RETIREMENT_AGE}.label`)}
            errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.RETIREMENT_AGE}.error`)}
            maxTimeframeErrorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.RETIREMENT_AGE}.maxTimeframeError`)}
            id={FIELDS.RETIREMENT_AGE}
            data-qa={FIELDS.RETIREMENT_AGE}
            hideErrorMessage
          />
        </Col>
      </Row>
      <FormErrorMessage id="error-message" fields={[FIELDS.AGE, FIELDS.RETIREMENT_AGE]} />
      <InputSelect
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.GENDER}.label`)}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.GENDER}.error`)}
        options={GENDERS}
        translationNamespace="try.form.common.genders"
        id={FIELDS.GENDER}
        data-qa={FIELDS.GENDER}
        hideErrorMessage
      />
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.label`)}
        id={FIELDS.STARTING_AMOUNT}
        data-qa={FIELDS.STARTING_AMOUNT}
        showTaxRelief={params[FIELDS.STARTING_AMOUNT_SHOW_TAX]}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.error`)}
        maxErrorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.maxError`)}
        noStack
      />
      <CheckboxWithModal
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT_SHOW_TAX}.label`)}
        id={FIELDS.STARTING_AMOUNT_SHOW_TAX}
        data-qa={FIELDS.STARTING_AMOUNT_SHOW_TAX}
      />
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.PENSION_TRANSFER}.label`)}
        id={FIELDS.PENSION_TRANSFER}
        data-qa={FIELDS.PENSION_TRANSFER}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.PENSION_TRANSFER}.error`)}
      />
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.MONTHLY_CONTRIBUTION}.label`)}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.MONTHLY_CONTRIBUTION}.error`)}
        id={FIELDS.MONTHLY_CONTRIBUTION}
        data-qa={FIELDS.MONTHLY_CONTRIBUTION}
        showTaxRelief={params[FIELDS.MONTHLY_CONTRIBUTION_SHOW_TAX]}
        noStack
      />
      <CheckboxWithModal
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.MONTHLY_CONTRIBUTION_SHOW_TAX}.label`)}
        id={FIELDS.MONTHLY_CONTRIBUTION_SHOW_TAX}
        data-qa={FIELDS.MONTHLY_CONTRIBUTION_SHOW_TAX}
      />
      <ContributionsToggle
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.EMPLOYER_CONTRIBUTIONS}.label`)}
        id={FIELDS.EMPLOYER_CONTRIBUTIONS}
        data-qa={FIELDS.EMPLOYER_CONTRIBUTIONS}
        value={showEmployerContributions}
        onChange={handleEmployerContributionsChange}
      />
      {showEmployerContributions && (
        <>
          <InputCurrency
            label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.EMPLOYER_MONTHLY_CONTRIBUTION}.label`)}
            id={FIELDS.EMPLOYER_MONTHLY_CONTRIBUTION}
            data-qa="pension-employer-monthly-contribution-input"
          />
          <Section stackSpacing={stackSpacing.MD} tagName={sectionTagNames.DIV}>
            <InputCurrency
              label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.EMPLOYER_ONE_OFF_CONTRIBUTION}.label`)}
              id={FIELDS.EMPLOYER_ONE_OFF_CONTRIBUTION}
              noStack
              data-qa="pension-employer-one-off-contribution-input"
            />
          </Section>
          <Section
            stackSpacing={stackSpacing.LG}
            tagName={sectionTagNames.DIV}
            data-qa="pension-employer-contributions-modal-link"
          >
            <ModalWithTrigger
              data-qa="pension-employer-contributions-modal"
              trigger={openModal => (
                <Button
                  cta={buttonCtaTypes.LINK}
                  size={buttonSizes.SM}
                  onClick={openModal}
                  data-qa="pension-employer-contributions-modal-open"
                >
                  {t(`${TRANSLATION_NAMESPACE}.${FIELDS.EMPLOYER_CONTRIBUTIONS}.modal.linkText`)}
                </Button>
              )}
            >
              <Text>{t(`${TRANSLATION_NAMESPACE}.${FIELDS.EMPLOYER_CONTRIBUTIONS}.modal.text`)}</Text>
            </ModalWithTrigger>
          </Section>
        </>
      )}

      <SelectInvestmentStyle
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.INVESTMENT_STYLE}.label`)}
        id={FIELDS.INVESTMENT_STYLE}
        data-qa={FIELDS.INVESTMENT_STYLE}
      />
      <RiskSlider
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.RISK_LEVEL}.label`)}
        id={FIELDS.RISK_LEVEL}
        data-qa={FIELDS.RISK_LEVEL}
      />
    </PotForm>
  );
};

PensionForm.propTypes = {
  t: func.isRequired,
  params: shape({}).isRequired
};

export default PensionForm;
