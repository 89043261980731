import React from 'react';
import { string, func, shape } from 'prop-types';
import { ProspectProjectionChart } from '@nm-portfolio-lib-web/projection-chart/components/ProspectProjectionChart';
import { wrapperTypes } from '@nm-portfolio-lib-web/common/constants';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import Amount, { amountStyle } from '@nutkit/component-amount';
import { Trans } from '@nm-utils-lib-web/translations';
import Link from '@nutkit/component-link';
import AboutProjectionModal from '@nm-pot/common/components/Projection/AboutProjectionModal';
import { Marketing } from '@nm-utils-lib-web/routes';

import { LIMITS } from '../../constants';

const TRANSLATION_NAMESPACE = 'try.projection.projectionChart';

const ProjectionChart = ({ mainHeadingId, inputValues, onError, t, wrapperType }) => {
  const isLisaWrapperType = wrapperType.toLowerCase() === wrapperTypes.LISA;
  const LABELS = {
    headings: {
      percentile50: t(`${TRANSLATION_NAMESPACE}.heading.projectedValue`),
      percentile95: t(`${TRANSLATION_NAMESPACE}.heading.highProjection`),
      percentile5: t(`${TRANSLATION_NAMESPACE}.heading.lowProjection`),
      currentDate: t(`${TRANSLATION_NAMESPACE}.heading.${isLisaWrapperType ? 'dateTitleLisa' : 'dateTitle'}`),
      governmentBonus: t(`${TRANSLATION_NAMESPACE}.heading.governmentBonus`),
      contribution: t(`${TRANSLATION_NAMESPACE}.heading.contribution`)
    },
    target: t(`${TRANSLATION_NAMESPACE}.targetTitle`),
    customColumn: t(`${TRANSLATION_NAMESPACE}.governmentBonus`),
    series: {
      moreLikely: t(`${TRANSLATION_NAMESPACE}.legend.moreLikely`),
      lessLikely: t(`${TRANSLATION_NAMESPACE}.legend.lessLikely`),
      contributed: t(`${TRANSLATION_NAMESPACE}.legend.contributions`),
      lowReturns: t(`${TRANSLATION_NAMESPACE}.legend.lowProjection`),
      governmentBonus: t(`${TRANSLATION_NAMESPACE}.legend.governmentBonus`)
    }
  };
  const { age, timeframe, monthlyContribution } = inputValues;
  const ageAfterTimeframe = isLisaWrapperType && parseInt(age) + parseInt(timeframe);

  const onModalToggle = () => {
    //@TODO - add tracking
  };

  return (
    <>
      <Heading id={mainHeadingId} level={headingLevels.TWO}>
        {t(`${TRANSLATION_NAMESPACE}.title`)}
      </Heading>
      {isLisaWrapperType && (
        <Text>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.paymentInfoLISA`}
            components={[
              <Amount value={timeframe} />,
              <Amount style={amountStyle.CURRENCY} value={monthlyContribution} maxDecimals={0} />,
              <Amount style={amountStyle.PERCENT} value={0.25} />
            ]}
          />
        </Text>
      )}
      <ProspectProjectionChart
        wrapperType={wrapperType}
        inputValues={inputValues}
        labels={LABELS}
        chartHeight={350}
        onError={onError}
        useAuthentication={false}
      />
      <Text>
        <AboutProjectionModal
          t={t}
          startingContribution={Number(inputValues.startingAmount)}
          monthlyContributions={Number(inputValues.monthlyContribution)}
          onModalToggle={onModalToggle}
        />
      </Text>
      {isLisaWrapperType && (
        <>
          {ageAfterTimeframe > LIMITS.LISA_WITHDRAW_AGE_LIMIT - 1 && (
            <Text data-qa="withdraw-over-60">{t(`${TRANSLATION_NAMESPACE}.descriptionLISA.afterWithdrawLimit`)}</Text>
          )}
          {ageAfterTimeframe < LIMITS.LISA_WITHDRAW_AGE_LIMIT && (
            <Text data-qa="withdraw-under-60">
              <Trans
                i18nKey={`${TRANSLATION_NAMESPACE}.descriptionLISA.beforeWithdrawLimit`}
                components={[
                  <Link data-qa="withdraw-under-60-link" href={Marketing.LISA_TERMS_ACCESS_SAVINGS_URL} isExternal>
                    link
                  </Link>
                ]}
              />
            </Text>
          )}
          <Text>{t(`${TRANSLATION_NAMESPACE}.disclaimerLISA`)}</Text>
        </>
      )}
    </>
  );
};

ProjectionChart.propTypes = {
  inputValues: shape({}),
  onError: func.isRequired,
  mainHeadingId: string,
  t: func.isRequired,
  wrapperType: string.isRequired
};

ProjectionChart.defaultProps = {
  inputValues: {},
  mainHeadingId: undefined
};

export default ProjectionChart;
