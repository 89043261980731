import potCommon from '@nm-pot/languages/nutmeg/common.json';
import potAllocation from '@nm-pot/languages/nutmeg/allocation.json';
import potInvestment from '@nm-pot/languages/nutmeg/investment.json';
import potCostsAndCharges from '@nm-pot/languages/nutmeg/costs-and-charges.json';
import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/nutmeg';
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/nutmeg';

import * as WRAPPER_TYPES from '../../constants/wrapperTypes';
import common from '../common.json';

import tabs from './tabs.json';
import headerPanel from './headerPanel.json';
import form from './form.json';
import startInvestingButtons from './startInvestingButtons.json';
import historical from './historical.json';
import portfolio from './portfolio.json';
import projection from './projection.json';
import modals from './modals.json';

export default {
  try: {
    common: {
      ...common,
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      wrapperTypes: {
        [WRAPPER_TYPES.SISA]: {
          ...common.wrapperTypes.sisa,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/isas`
        },
        [WRAPPER_TYPES.JISA]: {
          ...common.wrapperTypes.jisa,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/junior-isa`
        },
        [WRAPPER_TYPES.LISA]: {
          ...common.wrapperTypes.lisa,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/lifetime-isa`
        },
        [WRAPPER_TYPES.GIA]: {
          ...common.wrapperTypes.gia,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/general-investment-account`
        },
        [WRAPPER_TYPES.PENSION]: {
          ...common.wrapperTypes.pension,
          learnMoreURL: `${window.NutmegConfig.MARKETING_HOST}/pensions`
        }
      }
    },
    tabs,
    headerPanel,
    form,
    startInvestingButtons,
    historical,
    portfolio,
    projection,
    modals
  },
  pot: {
    allocation: potAllocation,
    common: {
      projection: {
        ...potCommon.projection,
        aboutProjectionModal: {
          ...potCommon.projection.aboutProjectionModal,
          ...projection.projectionChart.modal
        },
        aboutTheseCostsAndChargesModal: {
          ...potCommon.projection.aboutTheseCostsAndChargesModal,
          ...projection.projectedCostsAndCharges.modal
        }
      },
      historical: {
        ...potCommon.historical,
        modal: {
          ...potCommon.historical.modal,
          ...historical.modal
        }
      },
      button: potCommon.button
    },
    investment: potInvestment,
    costsAndCharges: potCostsAndCharges
  }
};
