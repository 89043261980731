import React from 'react';
import { string, func, shape, bool } from 'prop-types';
import Select from '@nutkit/component-select';

import { FIELDS } from '../../../../constants';

const InputSelect = ({ 'data-qa': dataQa, disabled, formik, id, label, options, t, translationNamespace }) => {
  const value = formik.values[id];
  const fieldOptions = Object.values(options).map(option => ({
    id: option,
    title: t(`${translationNamespace}.${option}`)
  }));

  const onSelectHandler = value => {
    formik.setFieldValue(id, value);
  };

  return (
    <div data-qa={dataQa}>
      <Select
        id={id}
        aria-controls={`${FIELDS.GENDER}`}
        data-qa={`${dataQa}-select-input`}
        title={t(`${translationNamespace}.placeholder`)}
        label={label}
        disabled={disabled}
        options={fieldOptions}
        onSelect={onSelectHandler}
        value={value}
      />
    </div>
  );
};

InputSelect.propTypes = {
  'data-qa': string,
  disabled: bool,
  formik: shape({ setFieldValue: func, handleBlur: func, values: shape({}) }),
  id: string.isRequired,
  label: string.isRequired,
  options: shape({}).isRequired,
  t: func.isRequired,
  translationNamespace: string.isRequired
};

InputSelect.defaultProps = {
  'data-qa': 'input-select',
  disabled: false,
  formik: {}
};

export default InputSelect;
