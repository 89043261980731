import * as analytics from '@nm-utils-lib-web/analytics';

import { TRACKING_CATEGORIES, TRACKING_EVENTS } from '../../constants';

export const trackLogin = props => {
  analytics.event({
    name: TRACKING_EVENTS.TRY_LOG_IN_CLICKED,
    properties: { ...props, category: TRACKING_CATEGORIES.ONBOARDING }
  });
};

export const trackStartInvesting = props => {
  analytics.event({
    name: TRACKING_EVENTS.TRY_START_INVESTING_CLICKED,
    properties: { ...props, category: TRACKING_CATEGORIES.ONBOARDING }
  });
};

export const trackDetailsSubmitted = props => {
  analytics.event({
    name: TRACKING_EVENTS.TRY_POT_DETAILS_SUBMITTED,
    properties: { ...props, category: TRACKING_CATEGORIES.ONBOARDING }
  });
};

export const trackProductTypeChange = props => {
  analytics.event({
    name: TRACKING_EVENTS.TRY_PRODUCT_TYPE,
    properties: { ...props, category: TRACKING_CATEGORIES.ONBOARDING }
  });
};

export const trackTabChange = eventName => {
  analytics.event({
    name: eventName,
    properties: { category: TRACKING_CATEGORIES.ONBOARDING }
  });
};
