export const FIELDS = {
  STARTING_AMOUNT: 'startingAmount',
  STARTING_AMOUNT_SHOW_TAX: 'startingAmountShowTax',
  MONTHLY_CONTRIBUTION: 'monthlyContribution',
  MONTHLY_CONTRIBUTION_SHOW_TAX: 'monthlyContributionShowTax',
  EMPLOYER_MONTHLY_CONTRIBUTION: 'employerMonthlyContribution',
  EMPLOYER_ONE_OFF_CONTRIBUTION: 'employerOneOffContribution',
  RISK_LEVEL: 'riskLevel',
  TIMEFRAME: 'timeframe',
  TARGET_AMOUNT: 'targetAmount',
  INVESTMENT_STYLE: 'investmentStyle',
  AGE: 'age',
  CHILD_AGE: 'childAge',
  RETIREMENT_AGE: 'retirementAge',
  PENSION_TRANSFER: 'pensionTransfer',
  EMPLOYER_CONTRIBUTIONS: 'employerContributions',
  GENDER: 'gender'
};

export const INITIAL_VALUES = {
  [FIELDS.STARTING_AMOUNT]: null,
  [FIELDS.STARTING_AMOUNT_SHOW_TAX]: false,
  [FIELDS.MONTHLY_CONTRIBUTION]: null,
  [FIELDS.MONTHLY_CONTRIBUTION_SHOW_TAX]: false,
  [FIELDS.EMPLOYER_MONTHLY_CONTRIBUTION]: null,
  [FIELDS.EMPLOYER_ONE_OFF_CONTRIBUTION]: null,
  [FIELDS.RISK_LEVEL]: null,
  [FIELDS.TIMEFRAME]: '',
  [FIELDS.TARGET_AMOUNT]: null,
  [FIELDS.INVESTMENT_STYLE]: null,
  [FIELDS.AGE]: '',
  [FIELDS.CHILD_AGE]: '',
  [FIELDS.RETIREMENT_AGE]: null,
  [FIELDS.PENSION_TRANSFER]: null,
  [FIELDS.EMPLOYER_CONTRIBUTIONS]: false,
  [FIELDS.GENDER]: null
};

export const FIELDS_LIST = Object.keys(INITIAL_VALUES);
export const FIELDS_INITIAL_VALUES = Object.values(INITIAL_VALUES);
