import React from 'react';
import { func, string, bool } from 'prop-types';
import Toggle, { ToggleGroup } from '@nutkit/component-toggle';
import { Text, textStyles } from '@nutkit/component-text';

const ContributionsToggle = ({ 'data-qa': dataQa, disabled, label, id, onChange, value }) => {
  return (
    <ToggleGroup noLastBorder noStack>
      <Toggle id={id} onChange={onChange} data-qa={dataQa} disabled={disabled} defaultActive={value}>
        <Text textStyle={textStyles.TITLE_3}>{label}</Text>
      </Toggle>
    </ToggleGroup>
  );
};

ContributionsToggle.propTypes = {
  label: string,
  'data-qa': string,
  value: bool,
  disabled: bool,
  onChange: func.isRequired,
  id: string.isRequired
};

ContributionsToggle.defaultProps = {
  disabled: false,
  value: false,
  label: '',
  'data-qa': 'contributions-toggle'
};

export default ContributionsToggle;
