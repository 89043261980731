import React from 'react';
import { monitor } from '@nm-utils-lib-web/monitoring';

const unbindImage = image => {
  // Reset callbacks
  image.onload = null;
  image.onerror = null;

  try {
    delete image.src;
  } catch (e) {
    //Some browsers throws errors for missing src
  }
};

export const getImageMeta = url => {
  return new Promise((resolve, reject) => {
    let img = new Image();

    img.onerror = () => {
      unbindImage(img);
      reject();
    };
    img.onload = () => {
      unbindImage(img);
      resolve(img);
    };
    img.src = url;
  });
};

export const getImageRatio = (width, height) => Math.floor((height / width) * 100);

const useImageMeta = url => {
  let initialMeta = {
    width: 0,
    height: 0,
    ratio: 0
  };
  let [imgMeta, setImgMeta] = React.useState(initialMeta);

  React.useEffect(() => {
    let mounted = true;

    getImageMeta(url)
      .then(({ width, height }) => {
        const ratio = getImageRatio(width, height);

        mounted && setImgMeta({ width, height, ratio });
      })
      .catch(() => monitor.captureException(`Error loading image at URL: ${url}`));

    return () => {
      mounted = false;
    };
  }, [url]);

  return imgMeta;
};

export default useImageMeta;
