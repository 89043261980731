import React from 'react';

export function recursiveMap(children, fn, cloneNonInputs = false) {
  return React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return cloneNonInputs ? child : null;
    }

    if (!child.props.id) {
      return cloneNonInputs
        ? React.cloneElement(child, {
            children: recursiveMap(child.props.children, fn, cloneNonInputs)
          })
        : recursiveMap(child.props.children, fn);
    }

    return fn(child);
  });
}
