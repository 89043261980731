import { ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import * as INVESTMENT_STYLES from './investmentStyles';

const AVAILABLE_INVESTMENT_STYLES = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
    FIXED: INVESTMENT_STYLES.FIXED,
    MANAGED: INVESTMENT_STYLES.MANAGED,
    SMART_ALPHA: INVESTMENT_STYLES.SMART_ALPHA,
    SRI: INVESTMENT_STYLES.SRI
  },
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
    MANAGED: INVESTMENT_STYLES.MANAGED,
    SRI: INVESTMENT_STYLES.SRI
  }
};

export default AVAILABLE_INVESTMENT_STYLES;
