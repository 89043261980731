import React from 'react';
import { func } from 'prop-types';

import { FIELDS } from '../../../constants';
import { InputNumber, InputCurrency, PotForm, RiskSlider, SelectInvestmentStyle } from '../common';

const TRANSLATION_NAMESPACE = 'try.form.SISA';
const COMMON_TRANSLATION_NAMESPACE = 'try.form.common';

const SISAForm = ({ t, ...rest }) => {
  return (
    <PotForm {...rest}>
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.label`)}
        id={FIELDS.STARTING_AMOUNT}
        data-qa={FIELDS.STARTING_AMOUNT}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.error`)}
        maxErrorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.STARTING_AMOUNT}.maxError`)}
      />
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.MONTHLY_CONTRIBUTION}.label`)}
        errorMessage={t(`${TRANSLATION_NAMESPACE}.${FIELDS.MONTHLY_CONTRIBUTION}.error`)}
        id={FIELDS.MONTHLY_CONTRIBUTION}
        data-qa={FIELDS.MONTHLY_CONTRIBUTION}
      />
      <InputNumber
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.TIMEFRAME}.label`)}
        id={FIELDS.TIMEFRAME}
        data-qa={FIELDS.TIMEFRAME}
        errorMessage={t(`${COMMON_TRANSLATION_NAMESPACE}.timeframeErrorMessage`)}
      />
      <InputCurrency
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.TARGET_AMOUNT}.label`)}
        id={FIELDS.TARGET_AMOUNT}
        data-qa={FIELDS.TARGET_AMOUNT}
      />
      <SelectInvestmentStyle
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.INVESTMENT_STYLE}.label`)}
        id={FIELDS.INVESTMENT_STYLE}
        data-qa={FIELDS.INVESTMENT_STYLE}
      />
      <RiskSlider
        label={t(`${TRANSLATION_NAMESPACE}.${FIELDS.RISK_LEVEL}.label`)}
        id={FIELDS.RISK_LEVEL}
        data-qa={FIELDS.RISK_LEVEL}
      />
    </PotForm>
  );
};

SISAForm.propTypes = {
  t: func.isRequired
};

export default SISAForm;
